<template>
	<div class="p-3" v-if="config">
		<div class="row">
			<div class="col-5 px-5">
				<form @submit.prevent="save">
					<div class="form-group">
						<h5>Version</h5>
						<input class="form-control" disabled v-model="model.Version"></input>
					</div>

					<div class="form-group">
						<h5>Bet per line</h5>
						<multiplier :min="1" v-model="model.Bet"></multiplier>
					</div>

					<div class="form-group" v-if="config">
						<h5>Lines</h5>

						<div class="form-group" v-for="payline in config.valid_paylines" :key="payline">
							<input type="radio" :value="payline" v-model="model.Paylines" :id="`pid-${payline}`"/>
							<label class="form-check-label ml-2" :for="`pid-${payline}`"
										 v-text="`${payline} payline`"></label>
						</div>
					</div>

					<div class="form-group">
						<h5>Spins</h5>
						<div class="text-small text-light">Number of spins to perform</div>
						<multiplier v-model="model.Spins" :step="1" class="w-100"></multiplier>
					</div>

					<div class="form-group">
						<h5>Notes</h5>
						<textarea v-model="model.Notes" class="form-control"></textarea>
					</div>

					<div class="form-group">
						<h5>Save log</h5>
						<div class="form-check">
							<input class="form-check-input mr-2" id="save_logs" type="checkbox" v-model="model.SaveLogs"
										 value="0">
							<label for="save_logs" class="form-check-label text-small text-light">You need csv logs of
								this test?</label>
						</div>
					</div>

					<div class="form-group">
						<h5>Save spins log</h5>
						<div class="text-muted text-small">another log file is created, containing a total per spin (including bonus
							/ free spin), useful for calculating the SD
						</div>
						<div class="form-check">
							<input class="form-check-input mr-2" id="save_spin_logs" type="checkbox" v-model="model.SaveSpinLogs"
										 value="0">
							<label for="save_spin_logs" class="form-check-label text-small text-light">You need csv logs of
								this test?</label>
						</div>
					</div>

					<div class="form-group">
						<h5>Save log (iTech format)</h5>
						<div class="text-muted text-small">another log file is created, with format required by iTech
						</div>
						<div class="form-check">
							<input class="form-check-input mr-2" id="save_spin_itech_logs" type="checkbox" v-model="model.SaveItechLogs"
										 value="0">
							<label for="save_spin_itech_logs" class="form-check-label text-small text-light">Generate text file</label>
						</div>
					</div>

					<button type="submit" class="btn btn-primary px-5 py-2" :disabled="saving">
						<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
									v-if="saving"></span>
						RUN TEST
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Multiplier from "@/components/slot-config-editor/prize/multiplier";
import api from '@/api'
import Index from "../../../../../../../components/slot-simulator/index";

export default {
	components: {Index, Multiplier},
	data() {
		return {
			config: null,
			model: {
				Bet: 1,
				Paylines: 1,
				Spins: 100000,
				SaveLogs: false,
				SaveSpinLogs: false,
		  	SaveItechLogs: false,
				Version: null
			},
			saving: false
		}
	},
	async mounted() {
		let tmp = await api.Projects.getProjectConfig(this.$route.params.cuuid)
		this.config = tmp.Configuration
		console.log('this.config', this.config)
		this.model.Paylines = this.config.valid_paylines[0]
		this.model.Version = this.config.version
	},
	methods: {
		async save() {
			this.saving = true
			let spinnerTest = await api.Spinner.createSpinnerTest(this.$route.params.cuuid, this.model).catch(() => this.saving = false)

			this.$router.push({
				name: 'projects-editor-uuid-cuuid-tests-spinner-spinner_id',
				params: {
					uuid: this.$route.params.uuid,
					cuuid: this.$route.params.cuuid,
					spinner_id: spinnerTest.Id
				}
			})
			this.saving = false
		}
	},
}
</script>
